.container{
    display: flex;
    flex-direction: column;
    gap: 0px;
    background-color: #3915AF;
    width: 100vw;
    height: 100vh;

    .header{
        // border: 1px solid black;
        margin: 0px auto;
        h1{
            font-size: 50px;
        }
        color: #FFD41F;
    }

    .list{
        margin: 0px 10%;
        h2 {
            font-size: 40px;
            color: #FFD41F;
        }
        p{
            text-decoration: none;
            margin: 0;
            color: white;
            font-weight: 600;
            font-size: 20px;
            a:link{
                color: #FFD41F;
            }
            a:visited{
                color: #FFD41F;
            }
        }
    }
}